<template>
  <div class="vx-row">
    <vs-popup fullscreen title="Créer un répertoire" :active.sync="popupRepertoire">
      <form>
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="vx-row">
              <div class="vx-col w-1/2">
                <div class="vx-row">
                  <div class="vx-col w-1/2">
                    <vs-select label="Type de répertoire" autocomplete class="w-full" v-model="typeRepertoire">
                      <vs-select-item  class="w-full" value="manuel" text="Manuel" />
                      <vs-select-item  class="w-full" value="bd" text="Base de donnée" />
                    </vs-select>
                  </div>
                  <div class="vx-col w-1/2">
                    <vs-input class="w-full" type="text" v-validate="'required|alpha'" name="libelle" label="Libellé*" v-model="libelle" :value="libelle" @change.native="libelle=$event.target.value" />
                    <span class="text-danger text-sm" v-show="errors.has(libelle)">{{ errors.first('libelle') }}</span>
                  </div>
                  <div class="vx-col w-full mt-5" v-if="typeRepertoire === 'bd'">
                    <vs-select label="Base de donnée client" autocomplete class="w-full" v-model="bdOfficine">
                      <vs-select-item  class="w-full" value="tous" text="Tous" />
                      <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
                    </vs-select>
                  </div>
                  <div class="vx-col w-full mt-5">
                    <vs-textarea label="Description" :value="description" @change.native="description=$event.target.value" />
                  </div>
                </div>
              </div>
              <div class="vx-col w-1/2">
                <div class="vx-row">
                  <div v-if="typeRepertoire === 'manuel'" class="vx-col md:w-1/2">
                    <vs-input class="w-full" type="text" label="Nom" :value="Nom" @change.native="Nom=$event.target.value" />
                  </div>
                  <div v-if="typeRepertoire === 'manuel'" class="vx-col md:w-1/2">
                    <vs-input class="w-full" type="text" label="Prénoms" :value="Prenoms" @change.native="Prenoms=$event.target.value" />
                  </div>
                  <div v-if="typeRepertoire === 'manuel'" class="vx-col  md:w-1/2 mt-5">
                    <vs-input class="w-full" type="text" label="Contact" :value="Contacts" @change.native="Contacts=$event.target.value" />
                  </div>
                  <div v-if="typeRepertoire === 'manuel'" class="vx-col md:w-1/2 mt-5">
                    <vs-input class="w-full" type="text" label="Email" :value="Email" @change.native="Email=$event.target.value" />
                  </div>
                </div>
                <div class="vx-row">
                  <div v-if="typeRepertoire === 'bd'" class="vx-col md:w-1/2">
                    <vs-select label="Genres" autocomplete class="w-full" v-model="genre">
                      <vs-select-item  class="w-full" value="tous" text="Tous" />
                      <vs-select-item  class="w-full" value="homme" text="Homme" />
                      <vs-select-item  class="w-full" value="femme" text="Femme" />
                      <vs-select-item  class="w-full" value="Enfant" text="Enfant" />
                    </vs-select>
                  </div>
                  <div v-if="typeRepertoire === 'bd'" class="vx-col md:w-1/2">
                    <vs-select v-model="trancheAge" autocomplete multiple :closeOnSelect="false"  class="selectExample w-full" label="Tranche d'âge">
                      <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in optionsTrangeAge" />
                    </vs-select>
                  </div>
                  <div v-if="typeRepertoire === 'bd'" class="vx-col md:w-1/2 mt-5">
                    <vs-select label="Prix moyen de commande" autocomplete class="w-full" v-model="prixMoyenCommande">
                      <vs-select-item  class="w-full" value="0 - 75000" text="0 - 75 000" />
                      <vs-select-item  class="w-full" value="75000 - 150000" text="75 000 - 150 000" />
                      <vs-select-item  class="w-full" value="150000 - 200000" text="150 000 - 200 000" />
                      <vs-select-item  class="w-full" value="200000 - Infinity" text="+200 000" />
                    </vs-select>
                  </div>
                  <div v-if="typeRepertoire === 'bd'" class="vx-col md:w-1/2 mt-5">
                    <vs-select label="Assuré" autocomplete class="w-full" v-model="assure">
                      <vs-select-item  class="w-full" :value="true" text="Oui" />
                      <vs-select-item  class="w-full" :value="false" text="Non" />
                    </vs-select>
                  </div>
                </div>
                <div class="vx-col w-full mt-5">
                  <div class="flex flex-wrap items-center justify-end" slot="footer">
                    <vs-button v-if="typeRepertoire === 'bd'" class="mr-4" @click="searchContactOnClientDb">Rechercher</vs-button>
                    <vs-button v-if="typeRepertoire === 'manuel'" class="mr-4" @click="AddContact">Ajouter</vs-button>
                    <vs-button type="border" color="danger" @click="resetFilter">Annuler</vs-button>
                  </div>
                </div>
              </div>
              <div class="vx-col w-full">
                <vs-divider class="mb-0"></vs-divider>
              </div>
              <div class="vx-col w-full">
                <vs-table stripe noDataText="Aucune donnée disponible" pagination search max-items="5" :data="contacts ? contacts : []">
                  <template slot="thead">
                    <vs-th sort-key="Nom">
                      Nom
                    </vs-th>
                    <vs-th sort-key="Prenoms">Prénoms</vs-th>
                    <vs-th sort-key="Contacts">Contact</vs-th>
                    <vs-th sort-key="Email">Email</vs-th>
                    <vs-th>Actions</vs-th>
                  </template>

                  <template slot-scope="{data}" v-if="contacts.length > 0">
                    <tbody>
                      <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td :data="tr.Nom">
                          {{tr.Nom}}
                          <template slot="edit">
                            <vs-input class="inputx w-full mt-4" type="text" label="Nom"  name="Nom" v-model="tr.Nom" @change.native="tr.Nom=$event.target.value" />
                          </template>
                        </vs-td>

                        <vs-td :data="tr.Prenoms">
                          {{tr.Prenoms}}
                          <template slot="edit">
                            <vs-input class="inputx w-full mt-4" type="text" label="Prénoms"  name="Prenoms" :value="tr.Prenoms" @change.native="tr.Prenoms=$event.target.value" />
                          </template>
                        </vs-td>

                        <vs-td :data="tr.Contacts">
                          {{tr.Contacts}}
                          <template slot="edit">
                            <vs-input class="inputx w-full mt-4" type="text" label="Contact"  name="Contacts" :value="tr.Contacts" @change.native="tr.Contacts=$event.target.value" />
                          </template>
                        </vs-td>

                        <vs-td :data="tr.Email">
                          {{tr.Email}}
                          <template slot="edit">
                            <vs-input class="inputx w-full mt-4" type="text" label="Email"  name="Email" :value="tr.Email" @change.native="tr.Email=$event.target.value" />
                          </template>
                        </vs-td>
                        <vs-td>
                          <feather-icon title="Supprimer" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="DeleteContactFromClientDb(tr._id)" />
                        </vs-td>
                      </vs-tr>
                    </tbody>
                  </template>
                </vs-table>
              </div>
              <div class="vx-col w-full mt-5" v-if="contacts.length > 0">
                <div class="flex flex-wrap items-center justify-end" slot="footer">
                  <vs-button class="mr-4" @click.prevent="createRepertoire">Enregistrer</vs-button>
                  <vs-button type="border" color="danger" @click="popupRepertoire = false; contacts = []; resetFilter">Annuler</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </vs-popup>
    <vs-popup fullscreen title="Modifier répertoire" :active.sync="popupUpdateRepertoire">
      <div class="vx-row">
        <div class="vx-col w-full ">
          <div class="vx-row">
            <div class="vx-col md:w-1/3">
              <div class="vx-row">
                <div class="vx-col w-full">
                  <vs-input class="w-full" type="text" label="Libellé" :value="libelleUpdate" @change.native="libelleUpdate=$event.target.value" />
                </div>
                <div class="vx-col w-full mt-5">
                  <vs-textarea label="Description" :value="descriptionUpdate" @change.native="descriptionUpdate=$event.target.value" />
                </div>
                <div class="vx-col w-full">
                  <vs-divider class="mb-0"></vs-divider>
                </div>
                <div class="vx-col md:w-1/2 mt-5">
                  <vs-input class="w-full" type="text" label="Nom" :value="NomUpdate" @change.native="NomUpdate=$event.target.value" />
                </div>
                <div class="vx-col md:w-1/2 mt-5">
                  <vs-input class="w-full" type="text" label="Prénoms" :value="PrenomsUpdate" @change.native="PrenomsUpdate=$event.target.value" />
                </div>
                <div class="vx-col md:w-1/2 mt-5">
                  <vs-input class="w-full" type="text" label="Contact" :value="ContactsUpdate" @change.native="ContactsUpdate=$event.target.value" />
                </div>
                <div class="vx-col md:w-1/2 mt-5">
                  <vs-input class="w-full" type="text" label="Email" :value="EmailUpdate" @change.native="EmailUpdate=$event.target.value" />
                </div>
                <div class="vx-col w-full mt-5">
                  <div class="flex flex-wrap items-center justify-end" slot="footer">
                    <vs-button class="mr-4" @click="AddContactUpdate">Ajouter</vs-button>
                    <vs-button type="border" color="danger" @click="popupUpdateRepertoire = false">Annuler</vs-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="vx-col md:w-2/3">
              <div class="vx-row">
                <template v-if="contactsUpdate.length > 0">
                  <div class="vx-col w-full">
                    <vs-table stripe pagination noDataText="Aucune donnée disponible" search max-items="15" :data="contactsUpdate ? contactsUpdate : []">
                      <template slot="header">
                        <vs-button class="mb-4 md:mb-0" @click="formatNomPrenomAndContact">FORMATER REPERTOIRE</vs-button>
                      </template>
                      <template slot="thead">
                        <vs-th sort-key="Nom">
                          Nom
                        </vs-th>
                        <vs-th sort-key="Prenoms">Prénoms</vs-th>
                        <vs-th sort-key="Contacts">Contact</vs-th>
                        <vs-th sort-key="Email">Email</vs-th>
                        <vs-th>Actions</vs-th>
                      </template>

                      <template slot-scope="{data}">
                        <tbody>
                          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td :data="tr.Nom">
                              {{tr.Nom}}
                              <template slot="edit">
                                <vs-input class="w-full mt-4" type="text" label="Nom"  name="Nom" :value="tr.Nom" @change.native="tr.Nom=$event.target.value" />
                              </template>
                            </vs-td>

                            <vs-td :data="tr.Prenoms">
                              {{tr.Prenoms}}
                              <template slot="edit">
                                <vs-input class="w-full mt-4" type="text" label="Prénoms"  name="Prenoms" :value="tr.Prenoms" @change.native="tr.Prenoms=$event.target.value" />
                              </template>
                            </vs-td>

                            <vs-td :data="tr.Contacts">
                              {{tr.Contacts}}
                              <template slot="edit">
                                <vs-input class="w-full mt-4" type="text" label="Contact"  name="Contacts" :value="tr.Contacts" @change.native="tr.Contacts=$event.target.value" />
                              </template>
                            </vs-td>

                            <vs-td :data="tr.Email">
                              {{tr.Email}}
                              <template slot="edit">
                                <vs-input class="w-full mt-4" type="text" label="Email"  name="Email" :value="tr.Email" @change.native="tr.Email=$event.target.value" />
                              </template>
                            </vs-td>
                            <vs-td>
                              <feather-icon title="Supprimer" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="DeleteContact(tr._id)" />
                            </vs-td>
                          </vs-tr>
                        </tbody>
                      </template>
                    </vs-table>
                  </div>
                  <div class="vx-col w-full mt-5">
                    <div class="flex flex-wrap items-center justify-end" slot="footer">
                      <vs-button class="mr-4" @click="updateRepertoire">Enregistrer</vs-button>
                      <vs-button type="border" color="danger" @click="popupUpdateRepertoire = false">Annuler</vs-button>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vs-popup>
    <vs-popup fullscreen title="Détails répertoire" :active.sync="popupRepertoireView">
      <div class="vx-row">
        <div class="vx-col md:w-1/4">
          <div class="vx-row">
            <div class="vx-col w-full">
              <vs-input disabled class="w-full" type="text" label="Titre" :value="repertoireShow ? repertoireShow.libelle : ''" @change.native="repertoireShow.libelle=$event.target.value" />
            </div>
            <!-- <div class="vx-col w-full"> <h4>Titre:</h4> {{repertoireShow ? repertoireShow.libelle : ''}} </div> -->
            <div class="vx-col w-full mt-5">
              <vs-textarea disabled label="Description" :value="repertoireShow ? repertoireShow.description : ''" @change.native="repertoireShow.description=$event.target.value" />
            </div>
            <!-- <div class="vx-col w-full"><h4>Description:</h4> {{repertoireShow ? repertoireShow.description : ''}} </div> -->
          </div>
        </div>
        <div class="vx-col md:w-3/4">
          <vs-table stripe pagination noDataText="Aucune donnée disponible" max-items="20" search :data="repertoireShow ? repertoireShow.contacts : []">
            <template slot="thead">
              <vs-th sort-key="Nom">
                Nom
              </vs-th>
              <vs-th sort-key="Prenoms">Prénoms</vs-th>
              <vs-th sort-key="Contacts">Contact</vs-th>
              <vs-th sort-key="Email">Email</vs-th>
            </template>

            <template slot-scope="{data}">
              <tbody>
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    {{tr.Nom}}
                  </vs-td>

                  <vs-td>
                    {{tr.Prenoms}}
                  </vs-td>

                  <vs-td>
                    {{tr.Contacts}}
                  </vs-td>

                  <vs-td>
                    {{tr.Email}}
                  </vs-td>
                </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </div>
      </div>
    </vs-popup>
    <div class="vx-col w-full">
      <vx-card >
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="flex items-end">
              <!-- <span class="font-medium text-lg leading-none">Officines:</span> -->
              <vs-select placeholder="Officine" autocomplete class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="IdOfficine" @change="CurrentOfficineSelectRepertoire()">
                <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
              </vs-select>
            </div>
            <vs-divider />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-table multiple stripe v-model="selected" pagination noDataText="Aucune donnée disponible" max-items="20" search :data="repertoires">
              <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">

                  <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                    <span class="mr-2 leading-none">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>

                  <vs-dropdown-menu>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Supprimer</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>CSV</span>
                      </span>
                    </vs-dropdown-item>

                  </vs-dropdown-menu>
                </vs-dropdown>
                <vs-button class="mb-4 ml-5 mr-5 md:mb-0" @click="popupRepertoire=true">CRÉER UN REPERTOIRE</vs-button>

              </template>
              <template slot="thead">
                <vs-th sort-key="libelle">
                  Libellé
                </vs-th>
                <vs-th>Nbr Contacts</vs-th>
                <vs-th>Status</vs-th>
                <vs-th>Actions</vs-th>
              </template>

              <template slot-scope="{data}">
                <tbody>
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                      {{tr.libelle}}
                    </vs-td>

                    <vs-td>
                      {{tr.contacts.length}}
                    </vs-td>

                    <vs-td>
                      <template v-if="tr.status">
                        <vs-chip transparent color="success">
                          Active
                        </vs-chip>
                      </template>
                      <template v-else>
                        <vs-chip transparent color="danger">
                          Inactif
                        </vs-chip>
                      </template>
                    </vs-td>

                    <vs-td class="whitespace-no-wrap">
                      <feather-icon title="Details" icon="EyeIcon" svgClasses="w-5 h-5 hover:text-grey stroke-current ml-4" @click.stop="repertoireShow = tr;popupRepertoireView=true" />
                      <feather-icon title="Editer" icon="Edit3Icon" svgClasses="w-5 h-5 hover:text-primary stroke-current ml-2" @click.stop="editRepertoire(tr)" />
                      <feather-icon title="Supprimer" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="confirmDeleteRecord(tr._id)" />
                    </vs-td>

                  </vs-tr>
                </tbody>
              </template>
            </vs-table>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { Validator } from 'vee-validate'
const dict = { custom: { libelle: { required: 'Veuillez saisir le libellé du répertoire', alpha: 'Le libellé ne peut contenir que des caractères alphabétiques'} }}
Validator.localize('en', dict)
export default {
  data () {
    return {
      activeUserInfos: null,
      IdOfficine: null,
      IdUser: null,

      popupRepertoire: false,
      selected:[],

      typeRepertoire: 'manuel',
      libelle: '',
      description: '',
      contacts: [],
      Nom: '',
      Prenoms: '',
      Contacts: '',
      Email: '',

      bdOfficine: null,
      genre: null,
      trancheAge: [],
      optionsTrangeAge: [
        {value: '0 - 06', text: '0 - 06'},
        {value: '06 - 12', text: '06 - 12'},
        {value: '12 - 18', text: '12 - 18'},
        {value: '18 - 30', text: '18 - 30'},
        {value: '30 - 40', text: '30 - 40'},
        {value: '40 - 50', text: '40 - 50'},
        {value: '50 - 2000', text: '+50'}
      ],
      prixMoyenCommande: null,
      assure: null,

      repertoireShow: null,
      popupRepertoireView: false,

      popupUpdateRepertoire: false,
      idRepertoire: null,
      libelleUpdate: null,
      descriptionUpdate: null,
      contactsUpdate: [],
      NomUpdate: '',
      PrenomsUpdate: '',
      ContactsUpdate: '',
      EmailUpdate: ''
    }
  },
  computed: {
    repertoires () {
      return this.$store.state.sms_marketing.repertoires
    },
    clients () {
      return this.$store.state.client.clients
    },
    officines () {
      return this.$store.state.officine.officines
    },
    contactsDb () {
      return this.$store.state.sms_marketing.contacts
    }
  },

  methods: {
    formatNomPrenomAndContact () {
      this.$store.dispatch('sms_marketing/formatNomPrenomAndContact', this.idRepertoire)
    },
    resetFilter () {
      // this.typeRepertoire = 'manuel'
      this.libelle = ''
      this.description = ''
      this.Nom = ''
      this.Prenoms = ''
      this.Email = ''
      this.bdOfficine = ''
      this.genre = ''
      this.trancheAge = []
      this.prixMoyenCommande = null
      this.assure = null
    },
    AddContact () {
      this.contacts.push({Nom: this.Nom, Prenoms: this.Prenoms, Contacts: this.Contacts, Email: this.Email})
      this.Nom = ''
      this.Prenoms = ''
      this.Contacts = ''
      this.Email = ''
    },
    AddContactUpdate () {
      this.contactsUpdate.push({Nom: this.NomUpdate, Prenoms: this.PrenomsUpdate, Contacts: this.ContactsUpdate, Email: this.EmailUpdate})
      this.NomUpdate = ''
      this.PrenomsUpdate = ''
      this.ContactsUpdate = ''
      this.EmailUpdate = ''
    },
    createRepertoire () {
      const payload = {
        IdOfficine: this.IdOfficine,
        IdUser: this.IdUser,
        libelle: this.libelle,
        description: this.description,
        contacts: this.contacts
      }
      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('sms_marketing/createRepertoire', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success !',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.$vs.loading.close()
          this.popupRepertoire = false
        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'error',
            text: `${err.message}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
          this.$vs.loading.close()
        })
    },
    editRepertoire (data) {
      this.idRepertoire = data._id
      this.libelleUpdate = data.libelle
      this.descriptionUpdate = data.description
      this.contactsUpdate = data.contacts

      this.popupUpdateRepertoire = true
    },
    updateRepertoire () {
      const payload = {
        IdOfficine: this.IdOfficine,
        IdUser: this.IdUser,
        libelle: this.libelleUpdate,
        description: this.descriptionUpdate,
        contacts: this.contactsUpdate,
        id: this.idRepertoire
      }
      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('sms_marketing/updateRepertoire', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success !',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.$vs.loading.close()
          this.idRepertoire = null
          this.popupUpdateRepertoire = false
        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'error',
            text: `${err.message}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
          this.$vs.loading.close()
        })
    },
    confirmDeleteRecord (id) {
      this.idRepertoire = id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Supprimer',
        text: 'Supprimer ce répertoire ?',
        acceptText: 'Supprimer',
        accept: this.deleteRecord
      })
    },
    deleteRecord () {
      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('sms_marketing/removeRepertoire', this.idRepertoire)
        .then(() => {
          this.$vs.notify({
            title: 'Supprimer',
            text: 'supprimer!',
            iconPack: 'feather',
            icon: 'icon-trash',
            color: 'danger'
          })
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'error',
            text: `${err.message}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
          this.$vs.loading.close()
        })
    },
    DeleteContact (id) {
      const index = this.contactsUpdate.findIndex((c) => c._id === id)
      this.contactsUpdate.splice(index, 1)
    },
    DeleteContactFromClientDb (id) {
      this.$store.dispatch('sms_marketing/removeContactOnClientContactDb', id)
    },
    searchContactOnClientDb () {
      const pyaload = {
        bdOfficine: this.bdOfficine,
        genre: this.genre,
        trancheAge: this.trancheAge,
        prixMoyenCommande: this.prixMoyenCommande,
        assure: this.assure
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('sms_marketing/searchContactOnClientDb', pyaload)
        .then((response) => {
          const indicatifPaysCIV = '225'
          const noEmptyContacts = response.filter((contact) => {
            return contact.Nom.trim() !== '' && contact.Contacts.trim() !== ''
          })
          noEmptyContacts.map((contact) => {
            const tabNom = contact.Nom.split(' ')
            if (tabNom.length > 1) {
              contact.Nom = tabNom[0]
              tabNom.splice(0, 1)
              contact.Prenoms = tabNom.flat().join(' ')
            }
            if (!contact.Contacts.startsWith(indicatifPaysCIV)) {
              contact.Contacts = (`${indicatifPaysCIV}${contact.Contacts}`).replace(/\s+/g, '')
            }
          })
          this.contacts = noEmptyContacts
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'error',
            text: `${err.message}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
          this.$vs.loading.close()
        })

    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {

        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err.message}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
      this.IdOfficine = this.activeUserInfos.IdOfficine
      this.IdUser = this.activeUserInfos._id

      this.getRepertoiresByOfficine(JSON.parse(localStorage.getItem('userInfo')).IdOfficine)
    },
    CurrentOfficineSelectRepertoire () {
      this.getRepertoiresByOfficine(this.IdOfficine)
    },
    getRepertoiresByOfficine (id) {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('sms_marketing/getRepertoiresByOfficine', id)
        .then(() => {
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err.message}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    }
  },
  created () {
    this.activeUserInfo()
    this.getOfficines()
  }
}
</script>

